import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import Layout from "../../components/3rd/layout";
import SEO from "../../components/seo";
import Wrapper from "../../components/wrapper";
import styles from "./index.module.scss";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="第一回BMS衆議院選" description="衆議院選挙を模した BMS チーム戦イベントです。各チーム（政党）はあらかじめ制作する BMS の方針（マニフェスト）を公開し、所属するメンバー（候補者）はマニフェストに沿った BMS を制作します。" mdxType="SEO" />
    <Wrapper className={styles.wrapper} mdxType="Wrapper">
      <h1>{`第一回BMS衆議院選`}</h1>
      <p>{`選挙は終了しました。`}</p>
      <p>{`与党 ピアノの党`}<br parentName="p"></br>{`
`}{`内閣総理大臣 ああああ`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
          "href": "https://venue.bmssearch.net/3"
        }}>{`イベント会場`}</a></p>
      <h2>{`主旨`}</h2>
      <p>{`衆議院選挙を模したBMSチーム戦イベントです。各チーム（政党）はあらかじめ制作するBMSの方針（マニフェスト）を公開し、所属するメンバー（候補者）はマニフェストに沿ったBMSを制作します。 インプレ（投票）により人気（民意）を問い、最も人気のあったチームは与党となり、与党チーム内で最も点数が高かった候補者は総理大臣になります。`}</p>
      <h2>{`開催スケジュール`}</h2>
      <dl className={styles.terms}>
  <dt>5/1(月)～6/30(金)</dt>
  <dd>参加表明</dd>
  <dt>7/20(木)～7/23(日)</dt>
  <dd>登録期間</dd>
  <dt>7/24(月)～8/20(日) 17:00</dt>
  <dd>インプレッション期間</dd>
  <dt>8/20(日) 18:00</dt>
  <dd>結果発表</dd>
      </dl>
      <h2>{`ルール`}</h2>
      <h3>{`参加表明期間（出馬表明）`}</h3>
      <div className={styles.detail_box}>
        <h4>{`政党立ち上げ`}</h4>
        <p>{`任意のチーム名（政党名）でチーム（政党）を立ち上げることができます。政党立ち上げの際には、その政党のBMSの方針（マニフェスト）を公開する必要があります。当該チームからBMSを出す候補者は、政党のマニフェストに従ってBMSを作らなければなりません。`}</p>
        <h4>{`立候補`}</h4>
        <p>{`すでにあるチーム（政党）からの参加を宣言することができます。また、新たに政党を立ち上げて 参加することもできます。どのチームにも属したくない場合は無所属での立候補も可能です。複数の政党から立候補することはできません。`}</p>
      </div>
      <p>{`※ チーム（政党）の乱立を防ぐため、立候補作者数が3人に満たない政党は政党として認められず、 その政党の立候補は無所属として出馬することになります。`}</p>
      <p>{`※ 本イベントにBMSを登録するためには、必ず参加表明期間内に立候補を行う必要があります。`}</p>
      <h3>{`登録期間`}</h3>
      <p>{`BMSの登録期間です。登録するBMSは以下の条件を満たしている必要があります。`}</p>
      <ul>
        <li parentName="ul">{`BRS規格(ver.001)に準拠していること`}</li>
        <li parentName="ul">{`一人一作品まで`}</li>
        <li parentName="ul">{`オリジナル作品であること`}</li>
        <li parentName="ul">{`所属チーム（政党）のマニフェストに則っていること（満たしていなくても失格の対象にはなりません）`}</li>
      </ul>
      <h3>{`インプレッション期間（投票期間）`}</h3>
      <p>{`投票の方法は以下の二種類あります。`}</p>
      <div className={styles.detail_box}>
        <h4>{`小選挙区インプレ`}</h4>
        <p>{`通常のBMSイベント同様好きな作者に好きなようにインプレをします。1～5点でのインプレになります。
自分の属する、もしくは何らかの形で参加している政党のBMSにはインプレしてはいけません。`}</p>
        <h4>{`比例代表インプレ`}</h4>
        <p>{`ひとり一票を持ち、好きな政党に投票することができます。
自分の属する、もしくは何らかの形で参加している政党へは投票してはいけません。`}</p>
      </div>
      <p>{`※ インプレはBRS規格(ver.001)に準拠していなければなりません`}</p>
      <h3>{`結果発表（開票）`}</h3>
      <p>{`以下の方法で得点の集計を行います。`}</p>
      <div className={styles.detail_box}>
        <h4>{`小選挙区インプレ`}</h4>
        <p>{`通常のBMSイベント同様、得点の合計を競います。上から40人（参加表明期間後に決定）を当選とし、所属する政党は議席を獲得します。同率順位は、平均点で比較します。`}</p>
        <h4>{`比例代表インプレ`}</h4>
        <p>{`政党投票では、各党の投票割合に応じて60議席を分配します。整数には直さず小数のまま計算します。党内順位は小選挙区インプレの得点に基づきますが、得点が同じだった場合平均点により順位を比較します。`}</p>
      </div>
      <p>{`小選挙区議席数と比例代表議席数の合計を総議席数とし、もっとも議席数が多かったチームを「与党」といたします。ただし、議席数が同じだった場合は比例代表の議席数が多いほうを与党とします。これも一致した場合、党の合計得点が高いほうを与党とします。
また、「与党」チームの中で最も得点が高かった作品を「内閣総理大臣」もしくは「首相」に指名します。`}</p>
      <p>{`※ ルールの性質上、人数の多いチームが有利になります。
しかし、今回のイベントは政権をとることよりも、自分たちの信念を貫きBMS界に一石を投じていただきたい、という思いから、チーム人数の違いによる議席数の補正は行いません。
有権者の顔色ばかり伺うのではなく、国民にとってこんなBMSが必要なのだ、という強い思いの感じられる作品をお待ちしております。`}</p>
    </Wrapper>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      